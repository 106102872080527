import "./src/styles/global.css"

const isImgInBetweenElems = (image, topElem, bottomElem) => {
    const imgBounding = image.getBoundingClientRect()
    const topElemBounding = topElem.getBoundingClientRect()
    const bottomElemBounding = bottomElem.getBoundingClientRect()

    return (
        imgBounding.top > topElemBounding.bottom &&
        imgBounding.bottom < bottomElemBounding.top
    )
}

export const onClientEntry = () => {
    window.addEventListener('scroll', (e) => {
        const menuImage = document.querySelector('.menu-image')
        const isMenuImgVisible = isImgInBetweenElems(
            menuImage,
            document.getElementById('our-restaurant'),
            document.getElementById('restaurant-information')    
        )

        if(isMenuImgVisible && !menuImage.classList.contains('show')) {
            menuImage.classList.add('show')
        } else if (!isMenuImgVisible && menuImage.classList.contains('show')) {
            menuImage.classList.remove('show')
        }

        const infoImages = document.getElementById('resImgContainer')
        const isInfoImagesVisible = isImgInBetweenElems(
            infoImages,
            document.getElementById('menu'),
            document.getElementById('reservation')
        )

        // console.log(infoImages)
        // console.log(document.getElementById('menu'))
        // console.log(document.getElementById('reservation'))

        if(isInfoImagesVisible && !infoImages.classList.contains('show')) {
            infoImages.classList.add('show')
        } else if (!isInfoImagesVisible && infoImages.classList.contains('show')) {
            infoImages.classList.remove('show')
        }
    })

    window.addEventListener('ft-widget-ready', (e) => {
        console.log('Formitable widget ready')
    })
}